import React from 'react';
import { withTranslation } from 'react-i18next';
import { getLinkByPageName } from '../../lib/links';
import Layout from '../atoms/layout';
import Eyebrow from '../atoms/titles/eyebrow';
import { Text4 } from '../atoms/body';
import {
  SectionTitle,
  SectionButtons,
  Contextual,
  EyebrowCss,
  BrowseCourses,
  BrowseCampus
} from './styles';
import {
  emitShowCampusDetailsEvent,
  emitShowCourseDetailsEvent
} from './ga_events';
import Props from '../interfaces/ContextualCta';
import config from '../config';

const ContextualCat: React.SFC<any> = (props: Props) => {
  const { t, className } = props;
  return (
    <Layout
      extend={Contextual}
      background={config.palette.skyBlue}
      className={className}
    >
      <SectionTitle>
        <Eyebrow extend={EyebrowCss}>
          {t('eyebrow:eyebrow.joinIronhack')}
        </Eyebrow>
        <b>{t('contextual-cta:contextualCta.readyToJoin.title')}</b>
        <Text4 as="p">
          {t('contextual-cta:contextualCta.readyToJoin.description')}
        </Text4>
      </SectionTitle>
      <SectionButtons>
        <BrowseCourses
          onClick={emitShowCourseDetailsEvent}
          href={getLinkByPageName('allCourses')}
        >
          {t('contextual-cta:contextualCta.readyToJoin.cta')}
        </BrowseCourses>
        <BrowseCampus
          onClick={emitShowCampusDetailsEvent}
          href={getLinkByPageName('allCampuses')}
        >
          {t('contextual-cta:contextualCta.readyToJoin.ctaSecondary')}
        </BrowseCampus>
      </SectionButtons>
    </Layout>
  );
};

export default withTranslation()(ContextualCat);
