import React from 'react';
import Helmet from 'react-helmet';

const Deindex: React.SFC<any> = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default Deindex;
