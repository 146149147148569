import styled, { css } from 'styled-components';
import config from '../config';
import { Link } from 'gatsby';

const SectionTitle = styled.section`
  display: flex;
  flex-direction: column;
  margin-right: 3.2rem;

  & > span {
    color: ${config.palette.lightBlue};
  }

  & > p {
    margin-top: 0.8rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  & > b {
    font-size: 1.9rem;
    line-height: 168%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    & > b {
      font-weight: 500;
      font-size: 2rem;
      line-height: 130%;
    }
  }
`;

const SectionButtons = styled.section`
  align-items: center;
  display: flex;
  width: 100%;

  & > a:last-child {
    margin-left: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;

    &:last-child {
      flex-direction: column;

      & > a:last-child {
        margin-left: 0rem;
      }

      a,
      button {
        margin-top: 1.6rem;
      }
    }
  }
`;

const Contextual = css`
  align-items: center;
  background-color: ${config.palette.skyBlueTransparent('0.1')};
  display: flex;
  padding-bottom: 3.2rem;
  padding-top: 3.2rem;
  justify-content: space-between;

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
  }
`;

const EyebrowCss = css`
  margin-bottom: 0.8rem;
  color: ${config.palette.lightBlue};
`;

const Buttons = css`
  font-size: 1.8rem;
  line-height: 168%;
  padding: 2.4rem 4.4rem;
  width: 25.6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 31.2rem;
    padding: 1.4rem 0;
    text-align: center;
  }
`;

const BrowseCourses = styled.a`
  width: 50%;
  background-color: ${config.palette.lightBlue};
  border-radius: 0.6rem;
  padding: 2.4rem 0;
  color: ${config.palette.white};
  font-size: 1.9rem;
  line-height: 168%;
  text-align: center;
  text-decoration: none;
  border: 0.2rem solid ${config.palette.lightBlue};

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    padding: 1.4rem 0;
    font-size: 1.6rem;
    line-height: 125%;
  }
`;

const BrowseCampus = styled(BrowseCourses)`
  background-color: initial;
  color: ${config.palette.lightBlue};
`;

export {
  SectionTitle,
  SectionButtons,
  Contextual,
  EyebrowCss,
  Buttons,
  BrowseCourses,
  BrowseCampus
};
