import { updateDataLayer } from '../../lib/datalayer';

function emitShowCourseDetailsEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'contextualized ctas::course'
  });
}

function emitShowCampusDetailsEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'contextualized ctas::campus'
  });
}

export { emitShowCourseDetailsEvent, emitShowCampusDetailsEvent };
